<script>
  import Api from "../../services/Api";

  import CardStats from "../Cards/CardStats.svelte";
  let logs = [];
  let loadData;
  let getLogs;
  let allComputers,
    notAssociatedComputers,
    associatedComputers,
    inActiveComputers,
    activeComputers,
    assignedLicenses,
    activeLicenses,
    purchasedLicense,
    inActiveLicenses;
  const bg = "/assets/img/back1.jpg";
  var token = localStorage.getItem("token");

  loadData = async () => {
    await Api.get(`user/stats`).then((result) => {
      if (!!result) {
   
        let stats = result.data;

        allComputers = stats.allComputers;
        associatedComputers = stats.associatedComputers;
        notAssociatedComputers = stats.notAssociatedComputers;
        inActiveComputers = stats.inActiveComputers;
        activeComputers = stats.activeComputers;
        assignedLicenses = stats.assignedLicenses;
        activeLicenses = stats.activeLicenses;
        inActiveLicenses = stats.inActiveLicenses;
        purchasedLicense=parseInt(inActiveLicenses)+parseInt(activeLicenses)+parseInt(assignedLicenses)
      }
    });
  };



  loadData();
</script>

<!-- Header -->
<div class="relative md:pt-32 pb-32 pt-12" style="
background-image: url({bg});
">
 

  <div class="px-4 md:px-10 mx-auto w-full" style="margin-top:20px">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <CardStats
            statSubtitle="purchased licenses"
            statTitle={purchasedLicense}
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-lightBlue-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <CardStats
            statSubtitle="active licenses"
            statTitle={activeLicenses}
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-purple-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <CardStats
            statSubtitle="computer associated"
            statTitle={associatedComputers}
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-pink-500" />
        </div>
        <!-- <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="inActiveLicenses"
            statTitle={inActiveLicenses}
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-red-500" />
        </div> -->
      </div>
    </div>
  </div>
</div>
