<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  import Sidebar from "../components/Sidebar/Sidebar.svelte";
  import { navigate } from "svelte-navigator";
  import AdminNavbar from "../components/Navbars/AdminNavbar.svelte";

  // pages for this layout
  import Dashboard from "../views/admin/Dashboard.svelte";
  import Settings from "../views/admin/Settings.svelte";
  import Tables from "../views/admin/Tables.svelte";
  import PcManagement from "../views/admin/PcManagement.svelte";
  import ShopManagement from "../views/admin/ShopManagement.svelte";
  import LicenseManagement from "../views/admin/LicenseManagement.svelte";
  import Download from "../views/admin/Download.svelte";
  import Profile from "../views/admin/Profile.svelte";
import BuyForm from "../views/admin/BuyForm.svelte";
import Guid from "../views/admin/Guid.svelte";


  export let location;
  export let admin = "";

  let auth;

  if (localStorage.getItem("user") === null) {
    auth = false;
  } else {
    auth = true;
  }

  if (!auth) {
    navigate("/");
    window.location.reload();
  }
</script>

{#if auth}
  <div>
    <Sidebar {location} />
    <AdminNavbar />

    <div class="relative md:ml-64 ">
      <!-- <HeaderStats /> -->
        <Router url="account">
          <Route path="dashboard" component={Dashboard} />
          <Route path="pcManagement" component={PcManagement} />
          <Route path="licenseManagement" component={LicenseManagement} />

          <Route path="shopManagement" component={ShopManagement} />
          <Route path="downloadList" component={Download} />

          <Route path="settings" component={Settings} />
          <Route path="tables" component={Tables} />
          <Route path="/profile" component={Profile} />
          <Route path="/buy" component={BuyForm} />
          <Route path="/guid" component={Guid} />
        </Router>
        <!-- <FooterAdmin /> -->
    </div>
  </div>
{/if}

<style>
  :global(.form){
    overflow: auto !important;
  }
</style>
