<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";
    import Dialog, {Title, Content, Actions} from "@smui/dialog";
    import Button, {Label} from "@smui/button";

    import IconButton from "@smui/icon-button";

    import * as yup from "yup";
    import {Form, Message} from "svelte-yup";
    import Api from "../../services/Api";

    let addShop;
    let shopName;
    let shopDesc;

    let updatedId;
    let removeShop;
    let updateShop;
    let rowsPerPage = 10;
    let currentPage = 1;
    let total;
    let items = [];
    let loadData;
    let open;
    let name;
    let description;
    let shopId;
    let columns = ["name", "description", "Actions"];
    let schema = yup.object().shape({
        name: yup.string().required().max(30).label("Name"),
    });
    let fields = {name: ""};
    let submitted = false;
    let isValid;
    let showModal = false;

    function toggleModal() {
        showModal = !showModal;
    }

    //for validations
    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {
            if (updatedId) {
                updateShop(updatedId);
            } else {
                addShop();
            }
        }
    }

    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    //server side data
    loadData = async (page, limit) => {
        await Api.get(
            `user/shops?paginated=1&limit=${limit}&page=${page}`
        ).then((result) => {
            if (!!result) {
                total = result.totalCount;
                items = result.data;
            }
        });
    };

    //create shop
    addShop = async () => {
        await Api.post(`user/shops`, {
            name: fields.name,
            description,
        }).then((result) => {
            if (!!result) {
                loadData(1, rowsPerPage);
                showModal = false;
            }
        });
    };

    //remove shop
    removeShop = async (shopId) => {
        await Api.delete(`user/shops/${shopId}`).then(
            (result) => {
                if (!!result) {
                    loadData(1, rowsPerPage);
                    showModal = false;
                }
            }
        );
    };

    //update shop
    updateShop = async (shopId) => {
        await Api.put(`user/shops/${shopId}`, {
            name: fields.name,
            description,
        }).then((result) => {
            if (!!result) {
                loadData(1, rowsPerPage);
                showModal = false;
            }
        });


    };

    loadData(1, rowsPerPage);

</script>

<style>
	.invalid {
		border-color: red !important;
	}
</style>
<div class="p-5" style="margin-top: 5rem">

    {#if showModal}
        <style>
									.error {
										color: red;
										margin: 5px;
									}

									.form {
										padding: 20px !important;
									}
        </style>
        <div
                style="margin:auto"
                class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center ">
            <div class="relative w-auto my-6 mx-auto max-w-sm">
                <!--content-->
                <div
                        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div
                            class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-3xl font-semibold">Shop</h3>
                        <button
                                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                on:click={() => (showModal = false)}>
                            <span
                                    class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 sm:w-8/12">
                                <Form
                                        class="form"
                                        {schema}
                                        {fields}
                                        submitHandler={formSubmit}
                                        {submitted}
                                        color="#b00020">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="name">
                                            Name
                                        </label>
                                        <input
                                                bind:value={fields.name}
                                                id="name"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Name"/>
                                        <Message name="name"/>
                                    </div>

                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="description">
                                            Description
                                        </label>
                                        <textarea
                                                bind:value={description}
                                                id="description"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="description"/>
                                    </div>

                                    <div
                                            class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                                            style=" margin-top: 20px;
                                    padding: 20px;">
                                        <!-- <button
                                        class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        on:click={() => (showModal = false)}>
                                        Close
                                    </button> -->

                                        <Button
                                                color="secondary"
                                                on:click={() => (showModal = false)}
                                                variant="raised"
                                                style="margin-right:10px">
                                            <Label>Close</Label>
                                        </Button>

                                        <Button
                                                color="secondary"
                                                variant="raised"
                                                letiant="raised">
                                            <Label>Save Changes</Label>
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <!--footer-->
                </div>
            </div>
        </div>
        <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    {/if}
    <!--dialog-->
    <Dialog
            bind:open
            aria-labelledby="simple-title"
            aria-describedby="simple-content">
        <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
        <Title id="simple-title">Remove Shop</Title>
        <Content id="simple-content">Are You Sure?</Content>
        <Actions>
            <Button>
                <Label>No</Label>
            </Button>
            <Button
                    on:click={() => {
                    console.log('shopid is..', shopId);

                    removeShop(shopId);
                }}>
                <Label>Yes</Label>
            </Button>
        </Actions>
    </Dialog>

    <DataTable style="width: 100%;">
        <Head>
            <Row>
                <button
                        on:click={() => {
                        fields.name = '';
                        description = '';
                        toggleModal();
                    }}
                        style="margin:20px"
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button">
                    <i class="fas fa-plus"></i>
                    Shop
                </button>
            </Row>

            <Row>
                {#each columns as column}
                    <Cell>{column}</Cell>
                {/each}
            </Row>
        </Head>
        <Body>
        {#each items as item}
            <Row>
                <Cell>{item.name}</Cell>
                <Cell>{item.description}</Cell>
                <Cell>
                    <IconButton
                            action=""
                            title=""
                            on:click={() => {
                                open = true;
                                shopId = item.id;
                            }}>
                        <i
                                style="color:red;font-size:14px"
                                class="fa fa-trash"
                                aria-hidden="true"/>
                    </IconButton>

                    <IconButton
                            class="fa fa-pencil"
                            on:click={() => {
                                updatedId = item.id;
                                fields.name = item.name;
                                description = item.description;

                                toggleModal();
                            }}>
                        <i
                                style="color:green;font-size:14px"
                                class="fa fa-pen"
                                aria-hidden="true"/>
                    </IconButton>
                </Cell>
            </Row>
        {/each}
        </Body>

        <Pagination slot="paginate">
            <!-- <div slot="rowsPerPage">
                    <Label>Rows Per Page</Label>

                        <Select variant="outlined" bind:value={rowsPerPage} noLabel  on:change={() => loadData(currentPage,rowsPerPage) }>
                            <Option value={10}>10</Option>
                            <Option value={25}>25</Option>
                            <Option value={100}>100</Option>
                          </Select>
                </div> -->
            <div slot="total">{start}-{end} of {total}</div>

            <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 1)}
                    disabled={currentPage === 1}>
                first_page
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => {
                    currentPage = currentPage - 1;
                    loadData(currentPage, rowsPerPage);
                }}
                    disabled={currentPage === 1}>
                chevron_left
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => {
                    currentPage = currentPage + 1;
                    loadData(currentPage, rowsPerPage);
                }}
                    disabled={currentPage === lastPage}>
                chevron_right
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}>
                last_page
            </IconButton>
        </Pagination>
    </DataTable>

</div>
