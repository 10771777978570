<script>
  import {link} from "svelte-routing";
  import SideBarItem from "./SideBarItem.svelte";

  let customerList;
  let customer;
  let customerId;

  let loadcustomers;

  let collapseShow = "hidden";
  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  export let location;
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  style="z-index:5000"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
    >
      <i class="fas fa-bars"></i>
    </button>

    <!-- Brand -->
    <img src="/assets/img/logo_head.png" alt="logo" />

    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              href="/"
            >
              Touch Hair
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow("hidden")}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">


        <li class="items-center">
          <a
            use:link
            href="/account/dashboard"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/account/dashboard'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                '/account/dashboard'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Dashboard
          </a>
        </li>

      </ul>

      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">

        <li class="items-center">
          <a
            use:link
            href="/account/pcManagement"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/account/pcManagement'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                '/account/pcManagement'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Pc Management
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/account/shopManagement"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/account/shopManagement'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-university mr-2 text-sm {location.href.indexOf(
                '/account/shopManagement'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Shop Management
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            href="/account/licenseManagement"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/account/licenseManagement'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-file mr-2 text-sm {location.href.indexOf(
                '/account/licenseManagement'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            License Management
          </a>
        </li>
        <li class="items-center">
          <a
            use:link
            href="/account/buy"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/account/buy'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-file mr-2 text-sm {location.href.indexOf(
                '/account/buy'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Buy
          </a>
        </li>


        <SideBarItem caption="Downloads" href="/account/downloadList" {location} />

           <li class="items-center">
          <a
            use:link
            href="/account/guid"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/account/guid'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-file mr-2 text-sm {location.href.indexOf(
                '/account/guid'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            guida
          </a>
        </li>

      </ul>

    </div>
  </div>
</nav>
