<script>
  import {Item, Text, Label} from '@smui/list';
  import LinearProgress from '@smui/linear-progress';

  export let itemData;

  const token = localStorage.getItem('token');
  const getFile = async (link) => {
    let downloadedFileName = 'SetupTouchHair.msi';
    console.log(itemData);
    isDownloading = true;

    await fetch(`${link}`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(resp => resp.blob()).then((blob) => {
      if (!!blob) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadedFileName);
        document.body.appendChild(link);
        link.click();
        isDownloading = false;
      }
    });
  };

  let isDownloading = false;

</script>

<Item on:click={getFile(itemData.link)} disabled="{isDownloading}">
	<Text>Version {itemData.title}</Text>

	{#if isDownloading}
		<div class="download-indicator-container">
			<Label>Downloading...</Label>
			<LinearProgress indeterminate/>
		</div>
	{/if}
</Item>

<style>
    .download-indicator-container {
        margin: 0 1rem;
    }
</style>