<script>
  import { Router, Route } from "svelte-routing";
  import Login from "../views/auth/Login.svelte";
  import Register from "../views/auth/Register.svelte";
  import ForgetPassword from "../views/auth/ForgetPassword.svelte";
  import ResetPassword from "../views/auth/ResetPassword.svelte";

  const registerBg2 = "../assets/img/register_bg_2.png";
  export let location;
  export let auth = "";

  // redirect to register
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('p');
  const reg = urlParams.get('reg');

  if ((page === "registrazione" || page === "lead") && reg === "new"){
    window.location = "auth/register";
  }

</script>

<div>

  <main>
    <section class="relative w-full h-full py-3 min-h-screen">
      <div
        class="absolute top- w-full h-full bg-no-repeat bg-full"
      ></div>



      <Router url="auth">
        <Route path="/" component="{Login}" />
        <Route path="register" component="{Register}" />
        <Route path="forgetPassword" component="{ForgetPassword}" />
        <Route path="reset-password" component="{ResetPassword}" />
      </Router>

    </section>
  </main>
</div>
