 <script>
    import { Accordion, AccordionItem } from "carbon-components-svelte";
    import List, { Item, Text } from "@smui/list";

    import Api from "../../services/Api";
    import DownloadItem from '../../components/Items/DownloadItem.svelte';

    let loadData;
    let items = [];
    let getFile;
    let downloadsDataList;

    loadData = async () => {
        await Api.get("user/downloads").then((result) => {
            if (!!result) {
                downloadsDataList = result;
            }
        });
    };

    loadData();
</script>


<div class="p-5" style="margin-top: 5rem">
<h3>Guida alla prima installazione di Touch Hair:</h3>

<div class="col-lg-9">

    <b>Passo 1:</b>

    <p>
        Per il corretto funzionamento di Touch Hair è necessario installare CR Runtime, un componente di terze parti usato in alcune funzioni del software.<br>
        È consigliabile installarlo prima di procedere con il passo 2.
    </p>
    {#if downloadsDataList}

    <List class="demo-list">

            <Item on:click={getFile(downloadsDataList.runtime)}>
                <Text>Download Version  CR Runtime <i class="fa fa-download fa-fw"></i></Text>
            </Item>

    </List>

    <b>Passo 2:</b>

    <p>
        Dopo aver installato CR Runtime, scarica e installa in pochi semplici passi Touch Hair.

    </p>



        <List class="demo-list">
            <DownloadItem itemData="{downloadsDataList.lastVersion}" />
        </List>

        {/if}

    </div>


{#if downloadsDataList}

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="z-index:5000;background:#fff">

        <Accordion size="xl">
                <AccordionItem title="Old versions">
                    <List class="demo-list">
                        {#each downloadsDataList.oldVersions as item}
                            <DownloadItem itemData="{item}" />
                        {/each}
                    </List>
                </AccordionItem>

        </Accordion>
    </div>
</div>
{/if}
</div>
