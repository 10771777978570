<!-- <script>
  // core components
  import CardSettings from "components/Cards/CardSettings.svelte";
  import CardProfile from "components/Cards/CardProfile.svelte";
  export let location;
</script>

<div class="flex flex-wrap">
  <div class="w-full lg:w-8/12 px-4">
    <CardSettings />
  </div>
  <div class="w-full lg:w-4/12 px-4">
    <CardProfile />
  </div>
</div> -->


<script>
  import { Datatable } from "svelte-simple-datatables";

  let rows;

  const settings = { columnFilter: true };
  let data = [
      {
          id: 1,
          first_name: "Tobie",
          last_name: "Vint",
          email: "tvint0@fotki.com",
      },
      {
          id: 2,
          first_name: "Zacharias",
          last_name: "Cerman",
          email: "zcerman1@sciencedirect.com",
      },
      {
          id: 3,
          first_name: "Gerianna",
          last_name: "Bunn",
          email: "gbunn2@foxnews.com",
      },
      {
          id: 4,
          first_name: "Bee",
          last_name: "Saurin",
          email: "bsaurin3@live.com",
      },
      {
          id: 5,
          first_name: "Meyer",
          last_name: "Garnul",
          email: "mgarnul4@yellowbook.com",
      },
      {
          id: 6,
          first_name: "Frederich",
          last_name: "Benley",
          email: "fbenley5@ameblo.jp",
      },
      {
          id: 7,
          first_name: "Becki",
          last_name: "Criag",
          email: "bcriag6@washingtonpost.com",
      },
      {
          id: 8,
          first_name: "Nichols",
          last_name: "Risom",
          email: "nrisom7@google.com.br",
      },
      {
          id: 9,
          first_name: "Ron",
          last_name: "Menendes",
          email: "rmenendes8@prnewswire.com",
      },
      {
          id: 10,
          first_name: "Thane",
          last_name: "Gammill",
          email: "tgammill9@com.com",
      },
      {
          id: 11,
          first_name: "Ramonda",
          last_name: "Yakobowitch",
          email: "ryakobowitcha@hibu.com",
      },
      {
          id: 12,
          first_name: "Rosalynd",
          last_name: "Connechie",
          email: "rconnechieb@chicagotribune.com",
      },
      {
          id: 13,
          first_name: "Vinny",
          last_name: "Hissett",
          email: "vhissettc@4shared.com",
      },
      {
          id: 14,
          first_name: "Mylo",
          last_name: "Tarbath",
          email: "mtarbathd@behance.net",
      },
      {
          id: 15,
          first_name: "Pierson",
          last_name: "Linny",
          email: "plinnye@altervista.org",
      },
  ];
</script>

<style>
  td {
      text-align: center;
      padding: 4px 0;
  }
</style>

<Datatable {settings} {data}>
  <thead>
      <th data-key="first_name">First Name</th>
      <th data-key="last_name">Last Name</th>
      <th data-key="email">Email</th>
  </thead>
  <tbody>
      {#each $rows as row}
          <tr>
              <td>{row.first_name}</td>
              <td>{row.last_name}</td>
              <td>{row.email}</td>
          </tr>
      {/each}
  </tbody>
</Datatable>
