<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";
    import Dialog, {Title, Content, Actions} from "@smui/dialog";
    import Button, {Label} from "@smui/button";

    import IconButton from "@smui/icon-button";

    import * as yup from "yup";
    import {Form, Message} from "svelte-yup";
    import Api from "../../services/Api";
    import {notify} from "svelte-notify";
    import Notifications from "svelte-notify";


    let schema = yup.object().shape({
        amount: yup.string().required().max(20).label("amount"),
        note: yup.string().max(30).label("note"),

    });
    let fields = {amount: "", note: ""};
    let submitted = false;
    let isValid;
    let buyForm;


    //for validations
    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {

            buyForm();

        }
    }

    buyForm = async () => {
        await Api.post(`user/licenses/request`, {
            count: fields.amount,
            note: fields.note,
        }).then((result) => {
            if (!!result) {
                notify({
                    title: "success",
                    message: "your buy license request  sent ",
                    timeout: 2000,
                    type: "success"

                });
                window.location.reload();

            }

        });
    };


</script>

<style>
	.error {
		color: red;
		margin: 5px;
	}

	.form {
		padding: 20px !important;
	}

	.invalid {
		border-color: red !important;
	}
</style>
<div class="p-5" style="margin-top: 5rem">

    <Notifications/>
    <div

            style="margin:auto"
            class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center ">
        <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">buy license</h3>

                </div>
                <!--body-->
                <div class="container px-4 mx-auto">
                    <div class="flex flex-wrap">
                        <div class="w-full px-4 sm:w-8/12">
                            <Form
                                    class="form"
                                    {schema}
                                    {fields}
                                    submitHandler={formSubmit}
                                    {submitted}
                                    color="#b00020">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="amount">
                                        amount
                                    </label>
                                    <input
                                            bind:value={fields.amount}
                                            id="amount"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="amount"/>
                                    <Message name="amount"/>
                                </div>

                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="note">
                                        note
                                    </label>
                                    <textarea
                                            bind:value={fields.note}
                                            id="note"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="note"/>
                                    <Message name="note"/>
                                </div>

                                <div
                                        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                                        style=" margin-top: 20px;
                                    padding: 20px;">
                                    <!-- <button
                                    class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    on:click={() => (showModal = false)}>
                                    Close
                                </button> -->


                                    <Button
                                            color="secondary"
                                            variant="raised"
                                            letiant="raised">
                                        <Label>Send</Label>
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <!--footer-->
            </div>
        </div>
    </div>

</div>



