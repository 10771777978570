<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // Admin Layout
  import Account from "./layouts/Account.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";


  // No Layout Pages
  import Landing from "./views/Landing.svelte";


  import Notifications from "svelte-notify";
import Dashboard from "./views/Dashboard.svelte";

  export let url = "";

</script>
<Notifications />
<Router {url}>
  <!-- admin layout -->
  <Route path="account/*account" component={Account} />
  <Route path="auth/*auth" component={Auth} />

  <!-- auth layout -->
  <Route path="/" component={Auth} />
  <!-- no layout pages -->
  <Route path="landing" component={Landing} />

  <!-- <Route path="/" component="{Index}" /> -->
  <Route path="/" component={Dashboard} />

</Router>
