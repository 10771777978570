<script>
  import Button, { Label } from "@smui/button";
  import { Form, Message } from "svelte-yup";
  import * as yup from "yup";
  import Api from "../../services/Api";
  import Notifications from "svelte-notify";
  import { notify } from "svelte-notify";

  const team2 = "/assets/img/team-2-800x800.jpg";

  let schema = yup.object().shape({
    firstName: yup.string().required().max(30).label("firstName"),
    lastName: yup.string().required().max(30).label("lastName"),
    email: yup.string().email().required().label("email"),
    pi: yup.string().optional().max(13).label("pi"),
    cf: yup.string().optional().max(16).label("cf"),
    cap: yup.string().optional().max(5).label("cap"),
    province: yup.string().optional().max(5).label("province"),
    address: yup.string().optional().max(80).label("address"),
  });

  let fields = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    pi: "",
    cf: "",
    cap: "",
    address: "",
  };
  let submitted = false;
  let isValid;
  function formSubmit() {
    submitted = true;
    isValid = schema.isValidSync(fields);
    if (isValid) {
      updateUser();
    }
  }
  let updateUser;

  let name,
    email,
    companyName,
    phone,
    address,
    cap,
    province,
    pi,
    cf,
    alternate_email,
    enabled,
    note,
    firstName,
    lastName;


  let adminInfo;
  function setData() {
    if (localStorage.getItem("user") !== null) {
      adminInfo = JSON.parse(localStorage.getItem("user"));
      fields.lastName = adminInfo.lastName;
      fields.email = adminInfo.email;
      fields.firstName = adminInfo.firstName;
      companyName=adminInfo.companyName;
      phone=adminInfo.phone;
      address=adminInfo.address;
      fields.cap=adminInfo.cap;
      province=adminInfo.province;
      fields.pi=adminInfo.pi;
      fields.cf=adminInfo.cf;
      alternate_email=adminInfo.alternate_email;
      note=adminInfo.note;
      enabled=adminInfo.enabled;

    }
  }

  updateUser = async () => {
    await Api.put(`user/profile`, {
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      companyName,
      phone,
      address,
      cap: fields.cap,
      province,
      pi: fields.pi,
      cf: fields.cf,
      alternate_email,
      note,
      enabled,
    }).then((result) => {
      if (!!result) {

        notify({
          title: "successful",
          message: "Update request send successfully",
          timeout: 2000,
          type: "success",
        });

      }
    });
  };

  setData();
</script>
<div class="p-5" style="margin-top: 5rem">

<div>
    <Notifications />
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg">
        <div class="px-6">
          <div
            style="margin:auto;top:50px"
            class=" w-full px-4 inset-0  outline-none focus:outline-none justify-center items-center ">
            <div class="relative w-auto mx-auto max-w-sm">
              <!--content-->
              <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-height">
                <!--header-->
                <div
                  class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 class="text-3xl font-semibold">Profile</h3>
                </div>
                <!--body-->
                <Form
                  class="form"
                  {schema}
                  {fields}
                  submitHandler={formSubmit}
                  {submitted}
                  color="#b00020">
                  <div class="container px-4 mx-auto">
                    <div class="flex flex-wrap">
                      <div class="w-full px-4 flex-1">
                        <div class="relative w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            for="firstName">
                            firstName
                          </label>
                          <input
                            bind:value={fields.firstName}
                            id="firstName"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="firstName" />
                          <Message name="firstName" />
                        </div>
                      </div>
                      <div class="w-full px-4 flex-1">
                        <div class="w-full px-4 flex-1">
                          <div class="relative w-full">
                            <label
                              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              for="lastName">
                              lastName
                            </label>
                            <input
                              bind:value={fields.lastName}
                              id="lastName"
                              type="text"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="lastName" />
                            <Message name="lastName" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-wrap">
                      <div class="w-full px-4 flex-1">
                        <div class="relative w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            for="companyName">
                            companyName
                          </label>
                          <input
                            bind:value={companyName}
                            id="companyName"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="companyName" />
                        </div>
                      </div>
                      <div class="w-full px-4 flex-1">
                        <div class="w-full px-4 flex-1">
                          <div class="relative w-full">
                            <label
                              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              for="email">
                              email
                            </label>
                            <input
                              bind:value={fields.email}
                              id="email"
                              type="email"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="email" />
                            <Message name="email" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-wrap">
                      <div class="w-full px-4 flex-1">
                        <div class="relative w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            for="phone">
                            phone
                          </label>
                          <input
                            bind:value={phone}
                            id="phone"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="phone" />
                        </div>
                      </div>
                      <div class="w-full px-4 flex-1">
                        <div class="w-full px-4 flex-1">
                          <div class="relative w-full">
                            <label
                              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              for="address">
                              address
                            </label>
                            <input
                              bind:value={address}
                              id="address"
                              type="text"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="address" />
                            <Message name="address"/>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-wrap">
                      <div class="w-full px-4 flex-1">
                        <div class="relative w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            for="cap">
                            cap
                          </label>
                          <input
                            bind:value={fields.cap}
                            id="cap"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="cap" />
                          <Message name="cap" />
                        </div>
                      </div>
                      <div class="w-full px-4 flex-1">
                        <div class="w-full px-4 flex-1">
                          <div class="relative w-full">
                            <label
                              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              for="province">
                              province
                            </label>
                            <input
                              bind:value={province}
                              id="province"
                              type="text"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="province" />
                            <Message name="province"/>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-wrap">
                      <div class="w-full px-4 flex-1">
                        <div class="relative w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            for="pi">
                            pi
                          </label>
                          <input
                            bind:value={fields.pi}
                            id="pi"
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="pi" />
                          <Message name="pi" />
                        </div>
                      </div>
                      <div class="w-full px-4 flex-1">
                        <div class="w-full px-4 flex-1">
                          <div class="relative w-full">
                            <label
                              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              for="cf">
                              cf
                            </label>
                            <input
                              bind:value={fields.cf}
                              id="cf"
                              type="text"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="cf" />
                            <Message name="cf" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-wrap">
                      <div class="w-full px-4 flex-1">
                        <div class="relative w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            for="alternate_email">
                            alternate_email
                          </label>
                          <input
                            bind:value={alternate_email}
                            id="alternate_email"
                            type="email"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="alternate_email" />
                        </div>
                      </div>
                      <div class="w-full px-4 flex-1">
                      </div>
                    </div>

                  </div>

                  <div
                    class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                    style=" margin-top: 20px;
                                      padding: 20px;">
                    <Button color="secondary" variant="raised" letiant="raised">
                      <Label>Save Changes</Label>
                    </Button>
                  </div>
                </Form>

                <!--footer-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</div>
<style>
  :global(.bottom-right){
    z-index: 1000;
  }
</style>
