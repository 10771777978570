<!--suppress EqualityComparisonWithCoercionJS, JSUnresolvedVariable -->
<script>
    import { DataTable } from "carbon-components-svelte";
    import "carbon-components-svelte/css/g10.css";
    import Button, { Label } from "@smui/button";
    import * as yup from "yup";
    import { Form, Message, isInvalid } from "svelte-yup";
    import IconButton from "@smui/icon-button";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Api from "../../services/Api";

    let open = false;
    let titleDialog;
    let computerId;
    let removeDesktop;
    let licId;
    let getLicenseList;
    let licenseList = [];
    let editComputer;
    let showModalEdit = false;
    let loadData;
    let shopList;
    let getShops;
    let assignLicense;
    let shops;
    let userStatData;
    let userStatObject;
    let showModal = false;
    $: shopId = "";
    let success = false;
    let count, type, swType;
    let allData;
    let checkBoxes = [
        "crash desk",
        "tax",
        "services",
        "stat.base",
        "products",
        "clients",
        "financial",
        "agenda",
        "multi users and operators",
        "marketing",
        "communication",
        "mobile app",
        "backup stat",
        "update",
    ];

    removeDesktop = async (computerId) => {
        await Api.delete(
            `user/computers/${computerId}/disassociate`
        ).then((result) => {
            if (!!result) {
                loadData();
                showModal = false;
            }
        });
    };

    //get user stat
    userStatData = async () => {
        await Api.get(`user/stats`).then(
            (result) => {
                if (!!result) {
                    userStatObject = result.data;
                }
            }
        );
    };

    getShops = async () => {
        await Api.get(`user/shops`).then((result) => {
            if (!!result) {
                shopList = result.data;
                console.log("shop list...", shopList);
            }
        });
    };

    getLicenseList = async () => {
        await Api.get(`user/licenses?page=1&limit=400`).then(
            (result) => {
                if (!!result) {
                    if (result.data && result.data.length > 0) {
                        licenseList = result.data.filter(
                            (license) => license.isAssignable
                        );
                    }
                }
            }
        );
    };

    loadData = async () => {
        await Api.get(`user/shops`).then((result) => {
            if (!!result) {
                allData=result;

                shops = [];
                if (result.notAssignedComputers.length > 0){
                    let object = {
                        computerList: result.notAssignedComputers,
                        id: 0,
                        shopName: "Not Assigned Computers",
                        computerCount: result.notAssignedComputers.length,
                    };
                    shops.push(object);
                }

                result.data.forEach((element) => {
                    let obj;

                    obj = {
                        computerList: element.computers,
                        id: element.id,
                        shopName: element.name,
                        computerCount: element.computers.length,
                    };

                    shops.push(obj);
                });

                shops.forEach((shops) => {
                    shops.computerList.forEach((computer) => {
                        computer.deadLine = new Date(
                            computer.deadLine
                        ).toLocaleString();
                        let licenseInfo;
                        if (computer.license) {
                            (computer["licenseIn"] = computer.license.type);
                            (computer["activated"] = computer.license.activationDate);
                            (computer["deadLine"] = computer.license.expirationDate);
                        } else {
                            (computer["licenseIn"] = "Demo");
                            (computer["activated"] = computer.activationDate);
                            (computer["deadLine"] = computer.demoExpirationDate);
                        }
                    });
                });
            }
        });
    };

    loadData();
    userStatData();

    //for validations
    let schema = yup.object().shape({
        count: yup.string().required().label("count"),
    });
    let fields = { count: "" };
    let submitted = false;
    let isValid = false;
    let checked = false;
    let permList = [];
    let update;
    let setDisassociateLicense;
    let disassociateLicense;

    setDisassociateLicense = async (computerId) => {
        await Api.put(`user/computers/${computerId}`, {
            disassociateLicense: true,
        }).then((result) => {
            if (!!result) {
                loadData();
                showModalEdit = false;
                licId = undefined;
            }
        });
    };

    update = async (computerId) => {
        let data;

        data = {
            clientDesc: editComputer.clientDesc,
            shopID: editComputer.shopID ?? "-1",
            licenseID: licId == "-1" ? undefined : licId,
        };

        await Api.put(`user/computers/${computerId}`, {
            ...data,
        }).then((result) => {
            if (!!result) {
                loadData();
                showModalEdit = false;
                licId = undefined;
            }
        });
    };

    function formSubmit1() {
        update(editComputer.id);
        loadData();
    }

</script>

    {#if showModalEdit}
        <div
            class=" px-4  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center "
        >
            <div class="relative my-6 mx-auto">
                <!--content-->
                <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none modal-height"
                >
                    <!--header-->
                    <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
                    >
                        <h3 class="text-3xl font-semibold">Computer</h3>
                        <button
                            class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            on:click={() => (showModalEdit = false)}
                        >
                            <span
                                class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                            >
                                ×
                            </span>
                        </button>
                    </div>
                    <!--body-->
                    <Form
                        class="form"
                        submitHandler={formSubmit1}
                        color="#b00020"
                    >
                        <div class="container px-4 mx-auto">
                            <div class="flex flex-wrap" style="padding:10px">
                                <div class="w-full px-4 flex-1">
                                    <div
                                        class="relative w-full"
                                        style="display: inline-flex;margin-left: -15px;"
                                    >
                                        <label
                                            style="margin-top: 15px;
                                         margin-right: 10px;"
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="description"
                                        >
                                            Descrizione:
                                        </label>
                                        <input
                                            style="width:50%;margin-bottom: 20px;"
                                            bind:value={editComputer.clientDesc}
                                            id="description"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="description"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="relative w-full"
                                    style="display: inline-flex"
                                >
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        style="margin-top: 15px;
                                         margin-right: 50px;"
                                        for="note"
                                    >
                                        shops:
                                    </label>
                                    <select
                                        style="width:50%;margin-bottom: 20px"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        bind:value={editComputer.shopID}
                                    >
                                        <option value="-1">Not Assigned</option>
                                        {#if shopList && shopList.length > 0}
                                            {#each shopList as shop}
                                                <option
                                                    value={shop.id}
                                                    selected={editComputer.shopID == shop.id}
                                                >
                                                    {shop.name}
                                                </option>
                                            {/each}
                                        {/if}
                                    </select>
                                </div>
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        pc brand:<span
                                            class=" val text-blueGray-600"></span>
                                    </label>
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        OS installed:<span
                                            class=" val text-blueGray-600"
                                            >{editComputer.operatingSystem}</span
                                        >
                                    </label>
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        Host Name:<span
                                            class=" val text-blueGray-600"
                                            >{editComputer.hostname}</span
                                        >
                                    </label>
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        software:<span
                                            class="val text-blueGray-600"></span>
                                    </label>
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        swType:<span
                                            class=" val text-blueGray-600"
                                            >{editComputer.swType}</span
                                        >
                                    </label>
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        version:<span
                                            class="val text-blueGray-600"
                                            >{editComputer.swVersion}</span
                                        >
                                    </label>

                                    {#if editComputer.license}
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="pc brand"
                                        >
                                            type:<span
                                                class="val text-blueGray-600"
                                                >{editComputer.license
                                                    .type}</span
                                            >
                                            <button
                                                on:click={() => {
                                                    setDisassociateLicense(editComputer.id);
                                                    loadData();
                                                    showModalEdit = false;
                                                }}
                                                class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                            >
                                                unassociated
                                            </button>
                                        </label>
                                    {:else}
                                        <div
                                            class="relative w-full"
                                            style="display: inline-flex"
                                        >
                                            <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                style="margin-top: 15px;
                                         margin-right: 10px;"
                                                for="note"
                                            >
                                                license:
                                            </label>
                                            <select
                                                bind:value={licId}
                                                style="width:50%;margin-bottom: 20px"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            >
                                                <option value="-1"
                                                    >No License</option
                                                >
                                                {#if licenseList.length > 0}
                                                    {#each licenseList as lic}
                                                        <option value={lic.id}>
                                                            {lic.key}
                                                        </option>
                                                    {/each}
                                                {/if}
                                            </select>
                                        </div>
                                    {/if}

                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        Key:<span class=" val text-blueGray-600"
                                            >{editComputer?.license?.key ??
                                                ""}</span
                                        >
                                    </label>
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="pc brand"
                                    >
                                        installed on:<span
                                            class=" val text-blueGray-600"
                                            >{editComputer.installDate}</span
                                        >
                                    </label>
                                    {#if !editComputer.license}
                                        <div
                                            class="relative w-full"
                                            style="display: inline-flex;margin-left: -15px;"
                                        >
                                            <label
                                                style="margin-top: 15px;
                                         margin-right: 10px;"
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="description"
                                            >
                                                demoExpirationDate:
                                                <span class=" val text-blueGray-600">
                                                    {editComputer.demoExpirationDate}
                                                </span>
                                            </label>
                                        </div>
                                    {:else}
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="pc brand"
                                        >
                                            expiration:<span
                                                class=" val text-blueGray-600"
                                                >{editComputer.license
                                                    .expirationDate}</span
                                            >
                                        </label>
                                    {/if}
                                </div>
                            </div>
                        </div>

                        <!--footer-->
                        <div
                            class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                        >
                            <Button
                                class="my-4 m-2"
                                color="secondary"
                                on:click={() => (showModalEdit = false)}
                                variant="raised"
                                style="margin-right:10px"
                            >
                                <Label>Close</Label>
                            </Button>

                            <Button
                                class="my-4 m-2"
                                color="secondary"
                                variant="raised"
                                letiant="raised"
                            >
                                <Label>Save Changes</Label>
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>

        <div class="opacity-25 fixed inset-0 z-40 bg-black overlay" />
    {/if}

    <div class="pcs flex flex-wrap">
        <div class="w-full mb-12 px-4" style="padding-top:5rem;">

            <DataTable
                style="padding-bottom:20px"
                headers={[
                    { key: "allComputers", value: "All Computers" },
                    { key: "activeLicenses", value: "Active Licenses" },
                    { key: "activeComputers", value: "Active Computers" },
                    { key: "associatedComputers", value: "Associates" },
                    { key: "inActiveComputers", value: "InActive Computers" },
                    { key: "inActiveLicenses", value: "InActive Licenses" },
                    { key: "notAssociatedComputers", value: "Not Associated" },
                    { key: "assignedLicenses", value: "Assigned Licenses" },
                ]}
                rows={[userStatObject]}
            />


            {#if allData && allData.data.length<=0 }
                <DataTable
                    style="padding-bottom:20px"
                    headers={[
                        { key: "clientDesc", value: "description" },
                        { key: "hostname", value: "hostname" },
                        {
                            key: "operatingSystem",
                            value: "operatingSystem",
                        },
                        { key: "swType", value: "swType" },
                        { key: "swVersion", value: "swVersion" },
                        { key: "licenseIn", value: "License" },
                        { key: "activated", value: "activated" },
                        { key: "deadLine", value: "deadLine" },
                        { key: "isActive", value: "status" },
                        { key: "actions", value: "ACTIONS" },
                    ]}
                    rows={allData.notAssignedComputers}>
                    <span slot="cell" let:cell let:row>
                          {#if cell.key === "actions"}
                                    <IconButton
                                            class="fa fa-cog"
                                            on:click={() => {
                                            showModalEdit = true;
                                            console.log("row is...", row);
                                            editComputer = row;

                                            getShops(customerId);
                                            getLicenseList(customerId);
                                        }}
                                    >
                                        <i
                                                style="color:green;font-size:14px"
                                                class="fa fa-wrench"
                                                aria-hidden="true"></i>
                                    </IconButton>
                                    <IconButton
                                            action=""
                                            title=""
                                            on:click={() => {
                                            open = true;
                                            computerId = row.id;
                                            titleDialog = row.clientDesc;
                                        }}
                                    >
                                        <i
                                                style="color:red;font-size:14px"
                                                class="fa fa-trash"
                                                aria-hidden="true"></i>
                                    </IconButton>
                                {:else}{cell.value}{/if}
                    </span>

                </DataTable>
            {/if}


            {#if allData && allData.data.length > 0}
            <DataTable
                zebra
                title="Negozi"
                size="short"
                expandable
                headers={[
                    { key: "shopName", value: "Shop title" },
                    { key: "computerCount", value: "Computers count" },
                ]}
                rows={shops}
            >
                <div slot="expanded-row" let:row>
                    <DataTable
                        size="short"
                        headers={[
                            { key: "clientDesc", value: "Description" },
                            { key: "hostname", value: "Host Name" },
                            {
                                key: "operatingSystem",
                                value: "OS",
                            },
                            { key: "swType", value: "Tipo" },
                            { key: "swVersion", value: "Versione" },
                            { key: "licenseIn", value: "Licenza" },
                            { key: "activated", value: "Attivato" },
                            { key: "deadLine", value: "Scadenza" },
                            { key: "actions", value: "Gest." },
                        ]}
                        rows={row.computerList}
                    >
                        <span slot="cell" let:cell let:row>
                            {#if cell.key === "actions"}
                                <IconButton
                                    class="fa fa-cog"
                                    on:click={() => {
                                        showModalEdit = true;
                                        console.log("row is...", row);
                                        editComputer = row;

                                        getShops();
                                        getLicenseList();
                                    }}
                                >
                                    <i  style="color:green;font-size:14px"
                                        class="fa fa-wrench"
                                        aria-hidden="true"></i>
                                </IconButton>
                                <IconButton
                                    action=""
                                    title=""
                                    on:click={() => {
                                        open = true;
                                        computerId = row.id;
                                        titleDialog = row.clientDesc;
                                    }}
                                >
                                    <i style="color:red;font-size:14px"
                                        class="fa fa-trash"
                                        aria-hidden="true"></i>
                                </IconButton>
                            {:else}{cell.value}{/if}
                        </span>
                    </DataTable>
                </div>
            </DataTable>
            {/if}
        </div>
    </div>

    <!--dialog-->
    <Dialog
        bind:open
        aria-labelledby="simple-title"
        aria-describedby="simple-content"
    >
        <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
        <Title id="simple-title">Disassocia {titleDialog}</Title>
        <Content id="simple-content">
            Attenzione, disassociando il PC dall'account il software su quel PC
            smetterà di funzionare.<br />
            Sarà comunque possibile riassociarlo a questo o ad un altro account tramite
            il software.<br />
            Una eventuale licenza associata sarà liberata ed utilizzabile su un altro
            PC associato a questo account.<br />
        </Content>
        <Actions>
            <Button>
                <Label>No</Label>
            </Button>
            <Button
                on:click={() => {
                    removeDesktop(computerId);
                }}
            >
                <Label>Yes</Label>
            </Button>
        </Actions>
    </Dialog>

<style>
    .val {
        margin-left: 20px;
    }

    .pcs :global(td) {
	    padding-left: .5rem !important;
    }
    .pcs :global(th){
     padding-left: .5rem;
    }
    .th-spacer {
	    margin-top: 6rem;
    }
    .overlay {
	    position: absolute;
	    width: 200%;
	    height: 200%;
	    opacity: 0.25;
	    top: -6rem;
    }
    .modal-height {
	    height: 65%;
	    max-height: 80vh;
    }
    tr.bx--parent-row.bx--expandable-row + tr[data-child-row] td{
	    padding-left: 0 !important;;
    }
</style>
