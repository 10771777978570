<script>
    import Api from "../../services/Api";
    import * as yup from "yup";
    import { Form, Message } from "svelte-yup";

    export let location;
    import Notifications from "svelte-notify";
    import { notify } from "svelte-notify";

    let password = "";
    let email = "";
    let error;

    let fields = { email: "" };
    let submitted = false;
    let isValid;

    let schema = yup.object().shape({
        email: yup.string().required().email().label("email"),
    });

    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {
            submit();
        }
    }

    const submit = async () => {
        try {

        } catch (error) {

        }
        await Api.post("auth/user/forget-password", {
            email: fields.email,
        }).then((result) => {
            if (!!result) {
                notify({
                    title: "successull",
                    message:
                        "your email has been sent check your email address",
                    timeout: 2000,
                    type: "success",
                });

            }else {
                notify({
                    title: "failed",
                    message:
                        "Email does not exists",
                    timeout: 2000,
                    type: "danger",
                });

            }
        });
    };
</script>

<div class="container mx-auto px-4 h-full">
    <Notifications />
    <div
        class="flex content-center items-center justify-center h-full"
        style="margin-top: 20%;"
    >
        <div class="w-full lg:w-4/12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
            >
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div class="text-blueGray-400 text-center mb-3 font-bold">
                        <small style="margin-top:20px">Forget Password</small>
                    </div>

                    <Form
                        class="form"
                        {schema}
                        {fields}
                        submitHandler={formSubmit}
                        {submitted}
                        color="#b00020"
                    >
                        <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                for="grid-email"
                            >
                                Email
                            </label>
                            <input
                                id="grid-email"
                                type="email"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email"
                                bind:value={fields.email}
                            />
                            <Message name="email" />
                        </div>

                        <div class="text-center mt-6">
                            <button
                                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="submit"
                            >
                                send
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</div>
