<script>
  import * as yup from "yup";
  import {Form, Message} from "svelte-yup";
  import Api from "../../services/Api";
  import {notify} from "svelte-notify";

  export let location;

  let schema = yup.object().shape({
    password: yup.string().required().min(8).label("password"),
    passwordConfirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    firstName: yup.string().required('firstname name is a required field').max(30).label("firstName"),
    lastName: yup.string().required('lastname is a required field').max(30).label("lastName"),
    email: yup.string().email().required().max(512).label("email"),
    companyName: yup.string().required('Business name is a required field').max(150).label("companyName"),
    phone: yup.string().required().max(30).label("phone"),

    address: yup.string().nullable().optional().max(80).label("address"),
    city: yup.string().optional().nullable().max(60).label("city"),
    province: yup.string().optional().nullable().max(5).label("province"),
    vatNumber: yup.string().optional().nullable().max(13).label("vatNumber"),
    tax: yup.string().optional().nullable().max(16).label("tax"),
    postalCode: yup.string().optional().nullable().max(5).label("postalCode"),
    termsAcceptance: yup.boolean().oneOf([true], 'Field must be checked')
  });
  let fields = {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    address: null,
    city: null,
    vatNumber: null,
    tax: null,
    postalCode: null,
    province: null,
    termsAcceptance: false,
  };
  let submitted = false;
  let isValid;
  let registerUser;
  let promoCode = "";

  const params = new URLSearchParams(window.location.search);
  if (params.has("cod")) {
    promoCode = params.get("cod");
  } else {
    promoCode = "";
  }

  registerUser = async () => {
    await Api.post(`auth/user/register`, {
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      companyName: fields.companyName,
      phone: fields.phone,
      address: fields.address,
      city: fields.city,
      province: fields.province,
      cap: fields.postalCode,
      pi: fields.vatNumber,
      cf: fields.tax,
      promoCode: promoCode,
      password: fields.password,
      passwordConfirmation: fields.passwordConfirmation,
    }).then((result) => {
      if (!!result) {
        notify({
          title: "successful",
          message: "successfully registered, please verify your email to enable the account",
          type: "success",
          showAlways: true,
        });

      } else {
        notify({
          title: "failed",
          message: "failed, please try again",
          timeout: 2000,
          type: "danger",
        });
      }
    });
  };

  function formSubmit(event) {
    submitted = true;
    isValid = schema.isValidSync(fields);

    if (isValid) {
      registerUser().then(() => {
        event.target.reset();
      });
    }
  }
</script>

<style>
</style>

<div class="container mx-auto h-full">
  <!-- <Notifications /> -->
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
      <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center mb-3">
            <h6 class="text-blueGray-500 text-sm font-bold">
              Touch Hair Registration Page
            </h6>
          </div>

          <hr class="mt-6 border-b-1 border-blueGray-300"/>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="text-blueGray-400 text-center mb-3 font-bold"></div>
          <Form {schema} {fields} submitHandler={formSubmit} {submitted}>

            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-email">
                *Email address
              </label>
              <input
                      bind:value={fields.email}
                      id="grid-email"
                      type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"/>
              <Message name="email"/>
            </div>
            <div class="container  mx-auto">
              <div class="flex flex-wrap mb-3">
                <div class="w-full pr-4 flex-1">
                  <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          for="grid-email">
                    *Password
                  </label>
                  <input
                    bind:value={fields.password}
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="password" />
                  <Message name="password" />
                </div>
                <div class="w-full pl-4 flex-1">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-email">
                    verify Password
                  </label>
                  <input
                    bind:value={fields.passwordConfirmation}
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password confirmation" />
                  <Message name="passwordConfirmation" />
                </div>
              </div>

              <div class="flex flex-wrap mb-3">
                <div class="w-full pr-4 flex-1">
                  <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          for="grid-email">
                    *First name
                  </label>
                  <input
                          bind:value={fields.firstName}
                          id="grid-firstName"
                          type="text"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Firstname"/>
                  <Message name="firstName"/>
                </div>
                <div class="w-full pl-4 flex-1">
                  <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          for="grid-email">
                    *last name
                  </label>
                  <input
                          bind:value={fields.lastName}
                          id="grid-lastName"
                          type="text"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Lastname"/>
                  <Message name="lastName"/>
                </div>
              </div>

              <div class="relative w-full mb-3">
                <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        for="grid-businessName">
                  *Business name
                </label>
                <input
                        bind:value={fields.companyName}
                        id="grid-businessName"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Business name"/>
                <Message name="companyName"/>
              </div>

              <div class="flex flex-wrap mb-3">
                <div class="w-full  flex-1">
                  <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          for="grid-phone">
                    *phone
                  </label>
                  <input
                          bind:value={fields.phone}
                          id="grid-phone"
                          type="text"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Phone"/>
                  <Message name="phone"/>
                </div>
              </div>

              <div class="relative w-full mb-3">
                <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        for="grid-streetAddress">
                  street address
                </label>
                <input
                        bind:value={fields.address}
                        id="grid-streetAddress"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Street address"/>
                <Message name="address" />

              </div>

              <div class="flex flex-wrap my-3">
                <div class="w-full pr-4 flex-1">
                  <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          for="grid-city">
                    city
                  </label>
                  <input
                    bind:value={fields.city}
                    id="grid-city"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="City" />
                  <Message name="city" />
                </div>
                <div class="w-full px-2 flex-1">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-city">
                    postal code
                  </label>
                  <input
                    bind:value={fields.postalCode}
                    id="grid-postalCode"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Postal code" />
                  <Message name="postalCode" />
                </div>
                <div class="w-full pl-4 flex-1">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-city">
                    province
                  </label>
                  <input
                    bind:value={fields.province}
                    id="grid-province"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Province" />
                  <Message name="province" />
                </div>
              </div>

              <div class="flex flex-wrap my-3">
                <div class="w-full pr-4 flex-1 my-2">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-email">
                    vat number
                  </label>
                  <input
                    bind:value={fields.vatNumber}
                    id="grid-vat"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="vat number" />
                  <Message name="vatNumber" />
                </div>
                <div class="w-full pl-4 flex-1 my-2">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-email">
                    c.tax
                  </label>
                  <input
                    bind:value={fields.tax}
                    id="grid-tax"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="C.tax" />
                  <Message name="tax" />
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full flex-1">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-phone">
                    invitation code
                  </label>
                  <input
                    bind:value={promoCode}
                    id="grid-invitationCode"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="invitation code" />
                </div>
              </div>

              <div>
                <label
                  class="inline-flex items-center cursor-pointer"
                  style="margin-top:20px ">
                  <input
                    bind:checked={fields.termsAcceptance}
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" />

                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    I have read and agree to the
                    <a
                      href="#pablo"
                      on:click={(e) => e.preventDefault()}
                      class="text-red-500">
                      Terms of services
                    </a>
                    and the

                    <a
                      href="#pablo"
                      on:click={(e) => e.preventDefault()}
                      class="text-red-500">
                      privacy policy
                    </a>
                  </span>
                </label>
                <Message name="termsAcceptance" />

                <br/>
                <span>
                  (*) Required fields
                </span>
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit">
                  Create Account
                </button>

              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</div>
