<script>
    import { link } from "svelte-routing";
    import Api from "../../services/Api";
    import { navigate } from "svelte-navigator";

    import * as yup from "yup";
    import { Form, Message } from "svelte-yup";
    export let location;
    import Notifications from "svelte-notify";
    import TextCard from "../../components/dashboard/TextCard.svelte";

    let password = "";
    let email = "";
    let error;

    let fields = { email: "", password: "" };
    let submitted = false;
    let isValid;

    let schema = yup.object().shape({
        email: yup.string().required().email().label("email"),
        password: yup.string().required().min(6).label("password"),
    });

    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {
            submit();
        }
    }

    const submit = async () => {
        await Api.post("auth/user/jwt", {
            email: fields.email,
            password: fields.password,
        }).then((result) => {
            if (!!result) {
                localStorage.setItem("token", result.jwt);
                localStorage.setItem("user", JSON.stringify(result.user));
                navigate("/account/dashboard");
                document.location.reload();
            }
        });
    };
</script>

<div class="container mx-auto px-4 h-full">
    <TextCard />

    <Notifications />
    <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div class="text-blueGray-400 text-center mb-3 font-bold">
                        <small style="margin-top:20px">Welcome to Touch hair</small>
                    </div>

                    <Form
                        class="form"
                        {schema}
                        {fields}
                        submitHandler={formSubmit}
                        {submitted}
                        color="#b00020">
                        <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                for="grid-email">
                                Email
                            </label>
                            <input
                                id="grid-email"
                                type="email"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email"
                                bind:value={fields.email} />
                            <Message name="email" />
                        </div>

                        <div class="relative w-full mb-3">
                            <label
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                for="grid-password">
                                Password
                            </label>
                            <input
                                id="grid-password"
                                type="password"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Password"
                                bind:value={fields.password} />
                            <Message name="password" />
                        </div>

                        <div class="text-center mt-6">
                            <button
                                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="submit">
                                Sign In
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="flex flex-wrap mt-6 relative">
                <div class="w-1/2">
                    <a
                    use:link
                        href="/auth/forgetPassword"

                        class="text-blueGray-800">
                        <small>Forgot password?</small>
                    </a>
                </div>
                <div class="w-1/2 text-right">
                </div>
            </div>
        </div>
    </div>


</div>
<footer class="block py-4">
    <div class="container mx-auto px-4">
      <hr class="mb-4 border-b-1 border-blueGray-200" />
      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4">

        </div>
        <div class="w-full  px-4">
          Il software TouchHair è ora gestito e sviluppato da ICTec Informatica

  Per qualsiasi informazione rivolgersi a info@touchhair.it Oppure chiamaci al numero 0372-920186
        </div>
      </div>
    </div>
  </footer>

