<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";
    import Button, {Label} from "@smui/button";

    import IconButton from "@smui/icon-button";

    import "flatpickr/dist/flatpickr.css";
    import Api from "../../services/Api";

    $: filters = "false";

    let value, formattedValue;
    let rowsPerPage = 10;
    let currentPage = 1;
    let total;
    let items = [];
    let loadData;
    let sort = "type";
    let sortDirection = "ascending";
    let version;
    let type;
    let permissions;
    let expirationDate;

    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    let link = "";

    loadData = async (page, limit) => {
        link = `user/licenses?page=${page}&limit=${limit}`;

        await Api.get(link).then((result) => {
            if (!!result) {
                console.log(result);
                items = result.data;
                total = result.totalCount;
            }
        });
    };
    loadData(1, rowsPerPage);

    function handleSort() {
        items.sort((a, b) => {
            const [aVal, bVal] = [a[sort], b[sort]][
                sortDirection === "ascending" ? "slice" : "reverse"
                ]();
            if (typeof aVal === "string") {
                return aVal.localeCompare(bVal);
            }
            return aVal - bVal;
        });

        items = items;
    }

</script>
<div class="p-5" style="margin-top: 5rem">
    <DataTable
            sortable
            bind:sort
            bind:sortDirection
            on:MDCDataTable:sorted={handleSort}
            style="width: 100%;">
        <Head>
            <Row>
                <Cell columnId="key" style="width: 100%;">
                    <Label>key</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="inUse" style="width: 100%;">
                    <Label>inUse</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="name" style="width: 100%;">
                    <Label>Name</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="clientDesc" style="width: 100%;">
                    <Label>clientDesc</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>

                <Cell columnId="swType" style="width: 100%;">
                    <Label>swType</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="type" style="width: 100%;">
                    <Label>type</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="purchaseDate" style="width: 100%;">
                    <Label>purchaseDate</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="activationDate" style="width: 100%;">
                    <Label>activationDate</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="expirationDate" style="width: 100%;">
                    <Label>expirationDate</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="version" style="width: 100%;">
                    <Label>version</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="permissions" style="width: 100%;">
                    <Label>permissions</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
            </Row>
        </Head>
        <Body>
        {#each items as item (item.id)}
            <Row>
                <Cell>{item.key}</Cell>
                <Cell>{item.inUse}</Cell>
                <Cell>{item.name}</Cell>
                <Cell>{item.clientDesc}</Cell>
                <Cell>{item.swType}</Cell>
                <Cell>{item.type}</Cell>
                <Cell>{item.purchaseDate}</Cell>
                <Cell>{item.activationDate}</Cell>
                <Cell>{item.expirationDate}</Cell>
                <Cell>{item.version}</Cell>
                <Cell>{item.permissions}</Cell>
            </Row>
        {/each}
        </Body>

        <Pagination slot="paginate">
            <div slot="total">{start}-{end} of {total}</div>

            <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 1)}
                    disabled={currentPage === 1}>
                first_page
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => {
                    currentPage = currentPage - 1;
                    loadData(currentPage, rowsPerPage);
                }}
                    disabled={currentPage === 1}>
                chevron_left
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => {
                    currentPage = currentPage + 1;
                    loadData(currentPage, rowsPerPage);
                }}
                    disabled={currentPage === lastPage}>
                chevron_right
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}>
                last_page
            </IconButton>
        </Pagination>
    </DataTable>
</div>
